import cn from "classnames";
import { NavLink } from "react-router-dom";

import { navigationList } from "./helpers";

import styles from "./styles.module.scss";
import constants from "lib/constants";
import { utilityFunctions } from "ui/utilities/utilityFunctions";

const {
  portalWorkflowTypeNames,
  listForSimulatorAccount,
  listForNotEnabledAccount,
  listForEnabledAccount,
  listForDocumentsAccount,
} = constants;

const SidebarNavigation = ({
  portalWorkflowType,
  activeSidebarItem,
  sidebarBlockCollapsed,
  onCollapseClick,
  onSideItemClick,
}) => {

  const filterListAccordingToApplicationStatus = () => {
    let listValue = null;
    if (portalWorkflowType === portalWorkflowTypeNames.Simulator)
      listValue = listForSimulatorAccount;
    if (portalWorkflowType === portalWorkflowTypeNames.AccountSetup)
      listValue = listForNotEnabledAccount;
    if (portalWorkflowType === portalWorkflowTypeNames.AccountOverview)
      listValue = listForEnabledAccount;
    if (portalWorkflowType === portalWorkflowTypeNames.Documents)
      listValue = listForDocumentsAccount;
    return listValue;
  };

  const filteredNavList = navigationList
    .map((i) =>
      filterListAccordingToApplicationStatus().includes(i.id) ? i : null
    )
    .filter((i) => i);

  const renderSideElements = filteredNavList.map((item) => {
    const isActive = activeSidebarItem?.id === item.id;
    return (
      <NavLink
        id={`sidebar-item-${item.id}`}
        to={item.destination}
        onClick={() => onSideItemClick(item)}
        className={cn(styles["navlink-wrapper"], {
          [styles["active-link"]]: isActive,
          [styles["collapsed-sidebar"]]: sidebarBlockCollapsed,
        })}
        key={item.id}
      >
        <img
          width="35"
          height="30"
          src={isActive ? item["icon-active"] : item.icon}
          alt={item.summary}
        />
        <p>{item.summary}</p>
      </NavLink>
    );
  });

  return (
    <>
      <div
        className={cn(styles["side-block"], {
          [styles["side-block-collapsed"]]: sidebarBlockCollapsed,
        })}
      >
        <div
          className={cn(styles["taskbar-brand"], {
            [styles["taskbar-brand-collapsed"]]: sidebarBlockCollapsed,
          })}
        >
          <a href={utilityFunctions.getLandingUrl() || "#"}>
            <img
              src={`${process.env.PUBLIC_URL}/${
                sidebarBlockCollapsed
                  ? "logo-letter.png"
                  : "zimtra_site_logo.png"
              }`}
              alt="zimtra-logo"
            />
          </a>
          {!sidebarBlockCollapsed && (
            <img
              onClick={onCollapseClick}
              id="close-sidebar-icon"
              className={styles["close-sidebar-icon"]}
              src={`${process.env.PUBLIC_URL}/icons/close-white.svg`}
              alt="close"
            />
          )}
        </div>
        <div className={styles["fixed-side-elements"]}>
          <div className={cn(styles["upper-side"])}>{renderSideElements}</div>
        </div>
        <div
          className={cn(styles["expand-button"], {
            [styles["collapse-button"]]: !sidebarBlockCollapsed,
            [styles["collapse-bounce"]]: sidebarBlockCollapsed,
          })}
          onClick={onCollapseClick}
        >
          <img
            src={`${process.env.PUBLIC_URL}/expand.svg`}
            alt="expand"
            className={sidebarBlockCollapsed ? "collapse-bounce" : ""}
          />
          {!sidebarBlockCollapsed && <p>Collapse Block</p>}
        </div>
      </div>
      {!sidebarBlockCollapsed && (
        <div className={styles["sideblock-black-bg"]} />
      )}
    </>
  );
};

export default SidebarNavigation;
