import { useState, useContext, useEffect } from "react";
import { ModalContext } from "ui/ui-kit/modal/ModalContext";
import { Modal, Icon } from "@lynx/ui-components";
import "./RecursiveAccordion.scss";

const MobileAccordionModal = ({ label, value, items, isNegative, closeModal }) => (
  <div className="modal-window">
    <Modal
      className="adjusted-net-modal cabinet-modals"
      overlayClassName="request-overlay"
      styleType="zimtra-primary"
      isOpen={true}
      hideBlur
      hasCloseIcon={true}
      onClose={closeModal}
      onClickOutside={closeModal}
    >
      <Modal.Header
        className="modal-inner-header"
        onClose={closeModal}
        title={<h2 className="zimtra-h2">{label}</h2>}
      />
      <Modal.Body className="modal-inner-body">
        <div className="mobile-accordion-modal">
          <RecursiveAccordion
            label={label}
            value={value}
            items={items}
            isNegative={isNegative}
            level={0}
            isInModal={true}
          />
        </div>
      </Modal.Body>
    </Modal>
  </div>
);

const RecursiveAccordion = ({
  label,
  value,
  items,
  isNegative = false,
  level = 0,
  isInModal = false,
}) => {
  const [isOpen, setIsOpen] = useState(isInModal);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 639);
  const { showModal, hideModal } = useContext(ModalContext);

  const handleModalClose = () => {
    hideModal();
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 639);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const hasNestedItems = items && Object.keys(items).length > 0;
  const isClickable = hasNestedItems && !(level === 1 && value === 0);
  const shouldShowArrow = isClickable && (!isMobile) && (level === 0 || !isMobile);

  const formatValue = (val) => {
    if (typeof val === "number") {
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Math.abs(val));
    }
    
    if (typeof val === "string") {
      // Remove currency symbols and any non-numeric characters except decimal point
      const numericValue = parseFloat(val.replace(/[^0-9.-]/g, ''));
      
      if (!isNaN(numericValue)) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(Math.abs(numericValue));
      }
    }
    
    return val;
  };

  const handleClick = (e) => {
    if (level === 0 && isMobile && !isInModal) {
      e.stopPropagation();
      showModal(
        <MobileAccordionModal
          label={label}
          value={value}
          items={items}
          isNegative={isNegative}
          closeModal={handleModalClose}
        />
      );
      return;
    }

    if (isClickable && (!isMobile || !isInModal)) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className={`rec-accordion-item level-${level} ${isMobile ? "mobile" : ""} ${isInModal ? "in-modal" : ""}`}>
      <div
        className={`accordion-header level-${level} ${
          isClickable && (!isMobile || !isInModal) ? "clickable" : ""
        }`}
        onClick={handleClick}
      >
        {value !== undefined && (
          <span className={`value ${isNegative ? "negative" : ""}`}>
            {isNegative ? "-" : ""}
            {formatValue(value)}
          </span>
        )}

        <div className={`value-container level-${level}`}>
          <span className="label">{label}</span>
          {shouldShowArrow && (
            <span className={`arrow ${isOpen ? "up" : "down"}`}>
              {isOpen ? "\u2303" : "\u2304"}
            </span>
          )}
          {isMobile && level === 0 && !isInModal && hasNestedItems && (
            <Icon name="info" className="mobile-indicator" />
          )}
        </div>
      </div>

      {(isOpen || (isInModal && isMobile)) && hasNestedItems && (
        <div className={`accordion-content level-${level}`}>
          {Object.entries(items).map(([itemLabel, itemValue]) => {
            const isObject = typeof itemValue === "object";
            const itemProps = {
              key: itemLabel,
              label: itemLabel,
              level: level + 1,
              isInModal,
            };

            if (isObject && itemValue !== null) {
              if ("value" in itemValue && "items" in itemValue) {
                return (
                  <RecursiveAccordion
                    {...itemProps}
                    value={itemValue.value}
                    items={itemValue.items}
                    isNegative={itemValue.value < 0}
                  />
                );
              } else {
                return <RecursiveAccordion {...itemProps} items={itemValue} />;
              }
            } else {
              return (
                <RecursiveAccordion
                  {...itemProps}
                  value={itemValue}
                  isNegative={typeof itemValue === "number" && itemValue < 0}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default RecursiveAccordion;
