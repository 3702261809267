import { useContext } from "react";
import { Button, Icon } from "@lynx/ui-components";
import { useDispatch, useSelector } from "react-redux";

import { statisticAPI } from 'api/stats-api';
import constants from "lib/constants";
import { ModalContext } from "ui/ui-kit/modal/ModalContext";
import ConfirmationModal from "ui/components/modals/confirmation/ConfirmationModal";
import { marketdataThunks } from "actions-thunks/marketdata-thunks";
import { getMarketsToSend, getMarketsFromExt } from "ui/marketdata-page/helpers";

const ModalActions = ({
  alreadyHadRequest = false,
  disableButton,
  localState,
  handleCancelMarketDataRequestModal,
  handleDiscardMarketDataRequestModal,
  setDataWasChanged,
  requestedMarketsData,
  filterMarketsToTradingPlatform,
  dataWasChanged,
  setDisableButton
}) => {
  const dispatch = useDispatch();
  const { currentAccount, extensions } = useSelector((state) => state.accounts);
  const { showModal, hideModal } = useContext(ModalContext);

  const { RequestTypes } = constants;

  const {
    updateChangeMarketsRequestTC,
    createChangeMarketsRequestTC,
  } = marketdataThunks;

  const onCancelClick = () => {
    setDisableButton(false);
    handleCancelMarketDataRequestModal({
      markets: getMarketsFromExt(
        "ext",
        requestedMarketsData,
        extensions
      )
    })
  };

  const onDiscardClick = (withDiscardingPrimaryButton, justHide) => {
    hideModal();
    if (justHide) return;
    setDisableButton(false);
    handleDiscardMarketDataRequestModal({ withDiscardingPrimaryButton });
  };

  const onOkayClick = (date, change) => {
    let body = {
      ext: {
        addMarkets: getMarketsToSend({ markets: localState.addMarkets, filterMarketsToTradingPlatform, }),
        removeMarkets: getMarketsToSend({ markets: localState.removeMarkets, filterMarketsToTradingPlatform, }),
      },
    };

    if (change) {
      body = { ...body, date };
      dispatch(
        updateChangeMarketsRequestTC(
          currentAccount._id,
          requestedMarketsData._id,
          body,
          false,
          "unblock"
        )
      );
    } else {
      body = { ...body, type: RequestTypes.MARKETDATA, duedate: date };
      statisticAPI.sendStatUsage(currentAccount._id, { page: 'Create request: Market Data' });
      dispatch(createChangeMarketsRequestTC(currentAccount._id, body));
    }
    setDataWasChanged(false);
  };

  const onCancelRequestClick = () => {
    showModal(
      <ConfirmationModal
        isApplyButtonRed
        showEditModal
        closeModal={() => onDiscardClick(false, true)}
        onApplyClick={onCancelClick}
        labels={{
          title: "Cancel market data request?",
          body: "Do you really want to cancel this market data request?",
          buttons: { apply: "Yes", decline: "No" },
        }}
      />
    );
  };

  return !alreadyHadRequest ?
    <div className="confirmation-buttons">
      <Button
        disabled={disableButton}
        id="md-conf-btn-ok"
        buttonStyle="zimtra-secondary"
        buttonClassName="apply-changes-button"
        onClick={() => onOkayClick(localState.effectiveDate)}
        label="Ok"
      />
      <Button
        id="md-conf-btn-cancel"
        onClick={() => onDiscardClick(true)}
        buttonStyle="zimtra-secondary"
        buttonClassName="discard-changes-button"
        label="Cancel"
      />
    </div> :
    <div className="confirmation-buttons spaced">
      <div className="d-flex flex-column">
        <Button
          disabled={!dataWasChanged || disableButton}
          onClick={() => onOkayClick(localState.effectiveDate, true)}
          buttonStyle="zimtra-secondary"
          buttonClassName="apply-changes-button mb-4"
          label="Apply changes"
        />
        <Button
          onClick={() => onDiscardClick(true)}
          buttonStyle="zimtra-secondary"
          buttonClassName="discard-changes-button"
          label="Cancel"
        />
      </div>
      <Button
        buttonStyle="zimtra-secondary"
        buttonClassName="discard-changes-button cancel-requet-button"
        onClick={onCancelRequestClick}
        label={
          <>
            <Icon name="trash" />
            <span>Cancel Request</span>
          </>
        }
      />
    </div>
};

export default ModalActions;
