import { useState } from "react";

import { Modal, Button, Dropdown } from "@lynx/ui-components";

import "./styles.scss";

const CreateNewRequestModal = ({ closeModal, updateCategory, categories }) => {
  const [isDisabled, setDisabled] = useState(true);
  const [activeCategory, setActiveCategory] = useState(null);

  const handleCategoryChange = (selectedCategory, subLevel = true) => {
    setActiveCategory(selectedCategory);
    setDisabled(false);
    if (subLevel) {
      updateCategory(selectedCategory);
    }
  };

  return (
    <div className="modal-window">
      <Modal
        className="new-request-modal cabinet-modals"
        overlayClassName="request-overlay"
        styleType="zimtra-primary"
        isOpen={true}
        hideBlur
        hasCloseIcon={false}
        onClickOutside={closeModal}
      >
        <Modal.Header
          className="modal-inner-header"
          onClose={closeModal}
          title={<h2 className="zimtra-h2">New Request</h2>}
        />
        <Modal.Body className="modal-inner-body">
          <div className="d-flex align-items-top">
            <div className="left-side">Category:</div>
            <div id="new-request-dropdown-category">
              <Dropdown
                options={categories}
                placeHolderClassName="request-dropdown__placeholder"
                className="request-dropdown"
                placeholder="Select category"
                onChange={(selectedCategory) =>
                  handleCategoryChange(selectedCategory, false)
                }
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-inner-footer d-flex justify-content-between">
          <Button
            size="md"
            id="new-request-next-btn"
            buttonStyle="button-variant-second"
            buttonClassName="cabinet-primary-btn"
            disabled={isDisabled}
            onClick={() => updateCategory(activeCategory)}
            label="Next"
          />
          <Button
            size="md"
            id="new-request-cancel-btn"
            buttonStyle="button-variant-fourth"
            buttonClassName="cabinet-secondary-btn"
            onClick={closeModal}
            label="Cancel"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateNewRequestModal;
