import { useDispatch } from "react-redux";

import { accountThunks } from "actions-thunks/account-thunks";

const shevron = (
  <img
    src={`${process.env.PUBLIC_URL}/shevron-down-green.svg`}
    alt="shevron"
  />
);

const AccountEnabledModal = ({
  currentAccount,
  setEnabledWindowOpened,
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    setEnabledWindowOpened(false);
    dispatch(
      accountThunks.updateAccountInfoTC(
        currentAccount._id,
        { clientID: currentAccount._id, isFirstModalShowed: true },
        currentAccount
      )
    );
  };

  return (
    <div className="acc-enable-window">
      <div className="dialog-block-background" />
      <div className="dialog-block">
        <h4>
          &#127881; Congratulations, {currentAccount.personal.firstName}{" "}
          {currentAccount.personal.lastName}
        </h4>
        <div className="dialog-body-block">
          <div className="mb-3">
            Your trading platform is now enabled and ready to trade!
          </div>
          <p>Please use this Trader Portal for the following:</p>
          <ul className="mb-3">
            <li>
              {shevron}
              <span>see your current balance and risk settings,</span>
            </li>
            <li>
              {shevron}
              <span> request a change to your risk settings,</span>
            </li>
            <li>
              {shevron}
              <span>communicate with our Support Team,</span>
            </li>
            <li>
              {shevron}
              <span>receive important/urgent info,</span>
            </li>
          </ul>
          <div className="mb-3">
            We are constantly expanding the range of the capabilities of the
            tools we offer you.
          </div>
          <div className="mb-3">
            Read more about the Portal{" "}
            <a
              target="_blank"
              href={`${window.location.origin}/faq/617a7ac30b22ab563defcb5a`}
              rel="noreferrer"
            >
              in our FAQ
            </a>
            .
          </div>
          <div>Zimtra Team</div>
          <hr />
          <button id="congratulation-ok-button" className="main-button dialog-button" onClick={handleClick}>
            OK
          </button>
        </div>
      </div>
    </div>
  );

};

export default AccountEnabledModal;
