import { forwardRef } from "react";
import moment from "moment";

import ModalActions from "./modalActions";
import { getTotalPriceHandler } from "ui/marketdata-page/helpers";
import DateInput from "ui/ui-kit/date-input/DateInput";

const ModalConfirmation = forwardRef(
  ({ buttonItem, primaryEntitlements, onDateChange, ...props }) => {
    return (
      <div className="confirmation-block">
        <div className="left-block">
          <p className="bold-titles">
            Please review and confirm the changes you are requesting:
          </p>
          <div className="first-block">
            <span className="bold-titles">1. Entitlement:</span>
            <span>
              {buttonItem} bundle will be applied with the entitlements shown
              above.
            </span>
          </div>
          <div>
            <span className="bold-titles">2. Total monthly cost:</span>
            <ul>
              <li>
                <div className="monthly-cost-item">
                  <span>current:</span>{" "}
                  <span>
                    {getTotalPriceHandler(
                      primaryEntitlements,
                      props.filterMarketsToTradingPlatform
                    )}
                  </span>
                </div>
              </li>
              <li>
                <div className="monthly-cost-item">
                  <span>after the change:</span>{" "}
                  <span>
                    {getTotalPriceHandler(
                      props.localState?.markets,
                      props.filterMarketsToTradingPlatform
                    )}
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="effective-date-block">
            <div className="effective-date-calendar">
              <span className="bold-titles">3. Effective date: </span>
              <div className="d-flex align-items-center calendar-container modal-row">
                <DateInput
                  name="start"
                  type="date"
                  id="md-effective-date"
                  min={moment.utc().startOf("day").format("YYYY-MM-DD")}
                  max={moment
                    .utc()
                    .add(1, "year")
                    .startOf("day")
                    .format("YYYY-MM-DD")}
                  value={moment
                    .utc(props.localState.effectiveDate)
                    .format("YYYY-MM-DD")}
                  onChange={(e) => {
                    const utcDate = moment
                      .utc(e.target.value)
                      .format("YYYY-MM-DD");
                    onDateChange(utcDate);
                  }}
                />
                {props.alreadyHadRequest &&
                  moment(props.requestedMarketsData.schedule.duedate).format(
                    "YYYY-MM-DD"
                  ) !==
                    moment(props.localState.effectiveDate).format(
                      "YYYY-MM-DD"
                    ) && (
                    <span className="previously-text">
                      Previously selected:{" "}
                      {moment.utc(
                        props.requestedMarketsData.schedule.duedate
                      ).format("YYYY-MM-DD")}
                    </span>
                  )}
              </div>
            </div>
            <div className="monthly-fee-text">
              Please note that the monthly fee is not prorated.
            </div>
          </div>
        </div>
        <ModalActions {...props} />
      </div>
    );
  }
);

export default ModalConfirmation;
