import { useContext, useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileAC } from "../../actions-thunks/actions";
import { Button } from "@lynx/ui-components";

import ChangePasswordBlock from "ui/change-password/ChangePassword";
import { ModalContext } from "ui/ui-kit/modal/ModalContext";
import ConfirmationModal from "ui/components/modals/confirmation/ConfirmationModal";
import useStatistic from "ui/hooks/useStatistic";

import "./EditProfile.scss";

const EditProfile = () => {
  const { profileData, profileActive } = useSelector(
    (state) => state.profile
  );
  const { currentAccount } = useSelector((state) => state.accounts);
  const { showModal, hideModal } = useContext(ModalContext);

  const [changePasswordBlock, setChangePasswordBlock] = useState(false);

  const dispatch = useDispatch();
  const username = profileData?.username || "";
  const firstname = currentAccount?.personal.firstName || profileData?.firstName || "";
  const lastname = currentAccount?.personal.lastName || profileData?.lastName || "";

  useStatistic(currentAccount?._id, "Open page Personal information");

  useEffect(() => {
    if (profileData?.changePasswordStatus === "success") {
      dispatch(updateProfileAC({ changePasswordStatus: "" }));
      const closeModal = () => {
        hideModal();
        setChangePasswordBlock(false);
      };
      showModal(
        <ConfirmationModal
          showEditModal
          closeModal={closeModal}
          labels={{
            title: "Password change",
            body: "Password was changed successfully.",
            buttons: { apply: "Ok" },
          }}
        />
      );
    }
  }, [profileData?.changePasswordStatus, dispatch, hideModal, showModal]);


  if (profileActive)
    return (
      <div className="edit-profile-page-block">
        <Card className="edit-profile-card">
          <div className="form-block">
            <Form className="form-wrapper">
              <Form.Group
                className="form-group-block mb-3"
                controlId="profile-name"
              >
                <Form.Label>First name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  readOnly
                  value={firstname}
                  placeholder="Your name"
                />
              </Form.Group>

              <Form.Group
                className="form-group-block mb-3"
                controlId="profile-lastname"
              >
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  readOnly
                  value={lastname}
                  placeholder="Your lastname"
                />
              </Form.Group>

              <Form.Group
                className="form-group-block mb-3"
                controlId="profile-phone"
              >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="username"
                  readOnly
                  value={username}
                  placeholder="Your email"
                />
              </Form.Group>
            </Form>
            {changePasswordBlock ? (
              <ChangePasswordBlock
                setChangePasswordBlock={setChangePasswordBlock}
              />
            ) : (
              <Button
                size="sm"
                buttonStyle="zimtra-secondary"
                buttonClassName="edit-profile-main-button"
                onClick={() => setChangePasswordBlock(!changePasswordBlock)}
                label="Change password"
              />
            )}
          </div>
        </Card>
      </div>
    );
  return null;
};

export default EditProfile;
