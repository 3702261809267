import { useEffect, useState } from "react";
import { Modal, Button, Dropdown, RadioGroup } from "@lynx/ui-components";

import constants from "lib/constants";

import "./styles.scss";
import DateInput from "ui/ui-kit/date-input/DateInput";
import moment from "moment";

const { RequestTypes } = constants;

const tomorrow = moment.utc().add(1, "days").startOf("day");
const nextYear = moment.utc().add(1, "years").startOf("day");

// Set min and max using the moment dates
const min = tomorrow.format("YYYY-MM-DD");
const max = nextYear.format("YYYY-MM-DD");

const checkAccountRequestType = (accountRequestType) => {
  const defaultDays = [
    { label: "Immediately", checked: true, value: "100" },
    { label: "Select Date", checked: false, value: "custom" },
  ];

  if (accountRequestType === "disable") {
    defaultDays.splice(1, 0, {
      label: "Today EOD",
      checked: false,
      value: "today",
    });
  }

  return defaultDays;
};

const CreateToggleAccountModal = ({
  accountRequestType = "enable",
  closeModal,
  updateCategory,
  categories,
  activeCategory,
  submitModal,
}) => {
  const defaultCategory = categories.find(
    (category) => category.value === activeCategory.value
  );
  const [requestDateOptions, setRequestDateOptions] = useState(() =>
    checkAccountRequestType(accountRequestType)
  );
  const [selectedRequestDate, setSelectedRequestDate] = useState(
    requestDateOptions[0]
  );
  const [activeCalendarDate, setActiveCalendarDate] = useState(min);
  const [isVisibleCalendar, setIsVisibleCalendar] = useState(false);

  const handleFormSubmit = () => {
    const body = {
      type: RequestTypes.TRADING_ACCESS,
      ext: {
        action: accountRequestType === "enable" ? "ENABLE" : "DISABLE",
      },
      ...(selectedRequestDate &&
        selectedRequestDate.value === "100" && { priority: 100 }),
      ...(selectedRequestDate &&
        selectedRequestDate.value === "today" && { eod: true }),
      ...(selectedRequestDate &&
        selectedRequestDate.value === "custom" && {
          duedate: activeCalendarDate,
        }),
    };

    submitModal({
      body,
      type: RequestTypes.TRADING_ACCESS,
    });
  };

  useEffect(() => {
    setRequestDateOptions(() => checkAccountRequestType(accountRequestType));
  }, [accountRequestType]);

  useEffect(() => {
    if (selectedRequestDate && selectedRequestDate.value === "custom") {
      setIsVisibleCalendar(true);
      setActiveCalendarDate(min);
    } else {
      setIsVisibleCalendar(false);
    }
  }, [selectedRequestDate]);

  const handleDateChange = (e) => {
    const selectedDate = moment.utc(e.target.value).startOf("day").toDate();
    setActiveCalendarDate(selectedDate);
  };

  return (
    <div className="modal-window">
      <Modal
        className="toggle-account-modal cabinet-modals"
        overlayClassName="request-overlay"
        styleType="zimtra-primary"
        isOpen={true}
        hideBlur
        hasCloseIcon={false}
        onClickOutside={closeModal}
      >
        <Modal.Header
          className="modal-inner-header"
          onClose={closeModal}
          title={<h2 className="zimtra-h2">New Request</h2>}
        />
        <Modal.Body className="modal-inner-body">
          <div className="d-flex align-items-top modal-row">
            <div className="left-side">Category:</div>
            <div>
              <Dropdown
                options={categories}
                defaultValue={defaultCategory.value}
                className="request-dropdown"
                placeholder="Select category"
                onChange={updateCategory}
              />
            </div>
          </div>
          <hr />
          <div className="d-flex align-items-center radio-group-container modal-row" id="new-toggle-request-radio-group">
            <RadioGroup
              itemDirection="column"
              data={requestDateOptions}
              onChange={(item) => setSelectedRequestDate(item)}
            />
          </div>
          <div className="d-flex align-items-center calendar-container modal-row">
            <DateInput
              isgreyed={isVisibleCalendar ? null : "true"}
              name="start"
              type="date"
              id="new-toggle-request-date"
              min={min}
              max={max}
              value={moment.utc(activeCalendarDate).format("YYYY-MM-DD")}
              onChange={handleDateChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-inner-footer d-flex justify-content-between">
          <Button
            size="md"
            id="new-toggle-request-create-btn"
            buttonStyle="button-variant-second"
            buttonClassName="cabinet-primary-btn"
            disabled={!selectedRequestDate}
            onClick={handleFormSubmit}
            label="Create"
          />
          <Button
            size="md"
            id="new-toggle-request-cancel-btn"
            buttonStyle="button-variant-fourth"
            buttonClassName="cabinet-secondary-btn"
            onClick={closeModal}
            label="Cancel"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateToggleAccountModal;
